/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { BUTTON_TYPES, EvContactUsButton } from '@evinced-private/ui-common';

import { EXTERNAL_HUBSPOT_SOURCE } from 'src/consts/products-consts';
import { useUserTenant } from 'src/providers/userTenantProvider/UserTenantProvider';
import { hubspotAPIKey } from 'src/services/analytics/HubspotService';

interface ContactUsButtonProps {
	title: string;
	type?: BUTTON_TYPES;
	description?: string;
	product?: string;
	additionalInfo?: any;
	accessibilityProps?: any;
}

export const ContactUsButton: FC<ContactUsButtonProps> = ({
	title,
	type,
	description,
	product,
	additionalInfo,
	accessibilityProps
}) => {
	const { user, tenant } = useUserTenant();
	return (
		<EvContactUsButton
			externalHubspotSource={EXTERNAL_HUBSPOT_SOURCE}
			portalId={hubspotAPIKey}
			formTitle={title}
			userEmail={user?.email}
			userTenant={tenant?.id}
			product={product}
			type={type}
			description={description}
			additionalInfo={additionalInfo}
			accessibilityProps={accessibilityProps}
		/>
	);
};
