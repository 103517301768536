import { EXTERNAL_LINKS } from '../helpers/ExternalLinks';
import { openInNewTab } from '../helpers/OpenLinksHelper';
import {
	getFlowAnalyzerForMobilePath,
	getMobileSDKPath,
	getSiteScannerPath,
	getWebSDKPath
} from '../helpers/RoutesHelper';
import {
	getDevDebuggerStoreLink,
	getFlowAnalyzerForWebStoreLink
} from '../services/DownloadLinksService';
import { TenantExpirationDetails } from '../services/TenantsService';
import { ProductType } from '../types/Tenant';

export interface IProductCardData {
	productType: ProductType;
	cardTitle: string;
	contentText: string;
	learnMoreLink: string;
	learnMoreButtonTitle: string;
	learnMoreButtonText: string;
	goToProductButtonTitle: string;
	goToProductButtonText: string;
	expirationDetails: TenantExpirationDetails | undefined;
	goToProductButtonOnClick?: () => void;
	primaryButtonMessage?: string;
	/** relevant to products that have redirection path */
	path?: string;
}
export const ProductCardsData = [
	{
		productType: ProductType.DESIGN_ANALYZER,
		cardTitle: 'Design Assistant',
		contentText:
			'Ensure your design adheres to the ARIA pattern guidelines and annotate it with instructions for developers.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_DESIGN_ANALYZER_LINK,
		learnMoreButtonTitle: 'Learn More About Design Assistant',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Open Design Assistant',
		goToProductButtonText: 'Download',
		goToProductButtonOnClick: () => openInNewTab(EXTERNAL_LINKS.DESIGN_ANALYZER_DOWNLOAD_LINK),
		expirationDetails: undefined
	},
	{
		productType: ProductType.UNIT_TESTER,
		cardTitle: 'Unit Tester',
		contentText:
			'Easily integrate with new or existing Jest environments to automatically detect accessibility issues in your component library project',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_UNIT_TESTER_LINK,
		learnMoreButtonTitle: 'Learn More About Unit Tester',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Open Unit Tester',
		goToProductButtonText: 'Get Started',
		goToProductButtonOnClick: () => openInNewTab(EXTERNAL_LINKS.UNIT_TESTER_LINK),
		expirationDetails: undefined
	},
	{
		productType: ProductType.DEVELOPER_ACCESSIBILITY_DEBUGGER,
		cardTitle: 'Dev Debugger',
		contentText:
			'Discover accessibility issues at an individual component level. Easily reproduce and test your fixes.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_DEV_DEBUGGER_LINK,
		learnMoreButtonTitle: 'Learn More About Dev Debugger',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Download Dev Debugger',
		goToProductButtonText: 'Download',
		goToProductButtonOnClick: async () => {
			const link = await getDevDebuggerStoreLink();
			openInNewTab(link?.downloadLink);
		},
		expirationDetails: undefined
	},
	{
		productType: ProductType.WEB_FLOW_ANALYZER,
		cardTitle: 'Web Flow Analyzer',
		contentText:
			'Automatically pinpoint accessibility issues in web pages and generate actionable reports.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_USER_FLOW_ANALYZER_LINK,
		learnMoreButtonTitle: 'Learn More About Web Flow Analyzer',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Download Web Flow Analyzer',
		goToProductButtonText: 'Download',
		goToProductButtonOnClick: async () => {
			const link = await getFlowAnalyzerForWebStoreLink();
			openInNewTab(link?.downloadLink);
		},
		expirationDetails: undefined
	},
	{
		productType: ProductType.WEB_SDK,
		cardTitle: 'Automation for Web',
		contentText:
			'Easily integrate with Cypress, Selenium Java, Playwright, WebdriverIO, and TestCafe tests to detect accessibility issues before push to production.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_WEB_AUTOMATION_LINK,
		learnMoreButtonTitle: 'Learn More About Automation for Web',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Get Automation for Web SDK',
		goToProductButtonText: 'Get SDK',
		path: getWebSDKPath(),
		expirationDetails: undefined
	},
	{
		productType: ProductType.SCANNER,
		cardTitle: 'Site Scanner',
		contentText:
			'Crawl and scan your entire website for accessibility issues, analyze large amounts of data and present actionable insights.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_SITE_SCANNER_LINK,
		learnMoreButtonTitle: 'Learn More About The Site Scanner',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Open Site Scanner',
		goToProductButtonText: 'Get Started',
		goToProductButtonOnClick: () => openInNewTab(getSiteScannerPath()),
		expirationDetails: undefined
	},
	{
		productType: ProductType.MOBILE_FLOW_ANALYZER,
		cardTitle: 'Mobile Flow Analyzer',
		contentText:
			'Automatically pinpoint accessibility issues in iOS and Android mobile applications and generate actionable reports.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_MOBILE_ANALYZER_LINK,
		learnMoreButtonTitle: 'Learn More About Mobile Flow Analyzer',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Open Mobile Flow Analyzer',
		goToProductButtonText: 'Get Started',
		path: getFlowAnalyzerForMobilePath(),
		expirationDetails: undefined
	},
	{
		productType: ProductType.MOBILE_SDK,
		cardTitle: 'Automation for Mobile',
		contentText:
			'Easily integrate with Appium, XCUI, WebdriverIO, Espresso and UIAutomator tests to detect accessibility issues before push to production.',
		learnMoreLink: EXTERNAL_LINKS.LEARN_MORE_MOBILE_AUTOMATION_LINK,
		learnMoreButtonTitle: 'Learn More About Automation For Mobile',
		learnMoreButtonText: 'Learn More',
		goToProductButtonTitle: 'Get Automation for Mobile SDK',
		goToProductButtonText: 'Get SDK',
		path: getMobileSDKPath(),
		expirationDetails: undefined
	}
];

export const MAIN_TITLE = 'main-title';

export const EXTERNAL_HUBSPOT_SOURCE = 'https://js.hsforms.net/forms/v2.js';
